/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  TrophyFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: t('Pools'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: 'Earn',
        href: '#sec',
        icon: EarnIcon,
        type: DropdownMenuItemType.EXTERNAL_LINK,
        showItemsOnMobile: false,
        items: []
      },
      {
        label: "",
        href: "#",
        icon: MoreIcon,
        items: [
          {
            label: "Marketplace",
            href: "#sec",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "GodCoins",
            href: "https://godholding.net/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Chart",
            href: "https://www.dextools.io/app/en/bnb/pair-explorer/0x264e9dc2fddff8791125fb44ced14bd876e11918?t=1722494119432",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Document",
            href: "https://godholding.net/wp-content/uploads/2024/06/god-holding-whitepaper-eng.pdf",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          }
        ],
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
